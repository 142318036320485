export enum FORM {
  // projects
  ADD_EDIT_PROJECT_SUMMARY = "ADD_EDIT_PROJECT_SUMMARY",
  ADD_MINE_MAJOR_APPLICATION = "ADD_MINE_MAJOR_APPLICATION",
  // reports
  VIEW_EDIT_REPORT = "VIEW_EDIT_REPORT",
  // permits
  REVOKE_DIGITAL_CREDENTIAL = "REVOKE_DIGITAL_CREDENTIAL",
  REQUEST_REPORT = "REQUEST_REPORT",
  // tailings
  ADD_TAILINGS = "ADD_TAILINGS",
  // documents
  REPLACE_DOCUMENT = "REPLACE_DOCUMENT",
  DELETE_DOCUMENT = "DELETE_DOCUMENT",
  ARCHIVE_DOCUMENT = "ARCHIVE_DOCUMENT",
}
