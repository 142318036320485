export const AUTHENTICATION = "AUTHENTICATION";
export const UNAUTHENTICATE_USER = "UNAUTHENTICATE_USER";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const GET_USER_INFO = "GET_USER_INFO";

// User Dashboard Info
export const USER_MINE_INFO = "USER_MINE_INFO";
export const GET_USER_MINE_INFO = "GET_USER_MINE_INFO";
export const GET_MINE_RECORD = "GET_MINE_RECORD";

export const MODAL = "MODAL";

export const STATIC_CONTENT = "STATIC_CONTENT";

// Variances
export const VARIANCES = "VARIANCES";
export const CREATE_MINE_VARIANCE = "CREATE_MINE_VARIANCE";
export const UPDATE_MINE_VARIANCE = "UPDATE_MINE_VARIANCE";
export const GET_MINE_VARIANCES = "GET_MINE_VARIANCES";
export const GET_VARIANCE = "GET_VARIANCE";
export const ADD_DOCUMENT_TO_VARIANCE = "ADD_DOCUMENT_TO_VARIANCE";
export const REMOVE_DOCUMENT_FROM_VARIANCE = "REMOVE_DOCUMENT_FROM_VARIANCE";
export const GET_VARIANCE_STATUS_OPTIONS = "GET_VARIANCE_STATUS_OPTIONS";
export const GET_VARIANCE_DOCUMENT_CATEGORY_OPTIONS = "GET_VARIANCE_DOCUMENT_CATEGORY_OPTIONS";
export const GET_COMPLIANCE_CODES = "GET_COMPLIANCE_CODES";

// Reports
export const REPORTS = "REPORTS";
export const GET_MINE_REPORTS = "GET_MINE_REPORTS";
export const UPDATE_MINE_REPORT = "UPDATE_MINE_REPORT";
export const GET_MINE_REPORT_DEFINITION_OPTIONS = "GET_MINE_REPORT_DEFINITION_OPTIONS";

//Verficable Credentials
export const VERIFIABLE_CREDENTIALS = "VERIFIABLE_CREDENTIALS";
export const CREATE_VC_WALLET_CONNECTION_INVITATION = "CREATE_VC_WALLET_CONNECTION_INVITATION";
